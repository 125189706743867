import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import chileanRut from "chilean-rut";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { confirmAlert } from "react-confirm-alert"; // Import

import moment from "moment";
import "moment/locale/es";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalSobreCupo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      canal: 5,

      profesional: props.profesional,
      fecha: props.fecha,

      especialidad: "",
      hora: "",
      minuto: "",
      motivoCreacion: "",
      rendimiento: "",
      rendimientos: [],
      horas: [],
      minutos: [],
      especialidades: [],
      rut: "",
      usuId: 0,

      open: true,
      formErrors: [],
    };
  }

  componentDidMount() {
    this.traerSobrecupo();
    this.traerEspecialidades(this.state.profesional);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirExito = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarExito();
  };

  traerSobrecupo() {
    const apiUrl = global.config.modulos.agendamientos + "sobrecupo/";
    axios.get(apiUrl, { params: { cesfam: this.state.cesfam, profesional: this.state.profesional, fecha: this.state.fecha }, headers: authHeader() }).then((res) => {
      var data = res.data;
      if (data.estado) {
        var minutos = data.minutos;
        var horas = data.horas;

        this.setState({
          minutos: minutos,
          horas: horas,
          minuto: minutos[0],
          hora: horas[0],
        });
      }
    });
  }

  validaciones() {
    const { rut, especialidad, rendimiento, motivoCreacion } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!rut) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut)) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut válido.";
    }

    if (especialidad == "") {
      formIsValid = false;
      formErrors["especialidadErr"] = "Selecciona el tipo de atención";
    }

    if (rendimiento == "") {
      formIsValid = false;
      formErrors["rendimientoErr"] = "Selecciona el rendimiento";
    }

    if (motivoCreacion == "") {
      formIsValid = false;
      formErrors["motivoErr"] = "Escribe el motivo";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  confirmarCambiarBloqueo = () => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ de cambiar el motivo del bloqueo de los horarios?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.cambiarBloqueoHorarios(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  crearConsulta = async () => {
    if (this.validaciones()) {
      var validarUser = await this.validarUsuario();
      if (validarUser) {
        var fechaAtencion = this.state.fecha + " " + this.state.hora + ":" + this.state.minuto;
        var data = {
          uspId: this.state.uspId,
          profesional: this.state.profesional,
          fechaAtencion: fechaAtencion,
          usuId: this.state.usuId,
          rendimiento: this.state.rendimiento,
          motivo: this.state.motivoCreacion,
          cesfam: this.state.cesfam,
          especialidad: this.state.especialidad,
        };

        let apiUrl = global.config.modulos.agendamientos + "crear_horario_sobrecupo/";

        const options = {
          method: "POST",
          data: data,
          headers: authHeader(),
        };

        axios(apiUrl, options).then((res) => {
          var data = res.data;
          this.salirExito();
          NotificationManager.success(data.mensaje);
        });
      }
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validarUsuario = async () => {
    var formIsValid = false;
    if (this.validaciones()) {
      var data = {
        rut: this.state.rut,
        canal: this.state.canal,
        cesfam: store.getState().cesfam,
      };

      let apiUrl = global.config.modulos.usuarios + "por_rut/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      var res = await axios(apiUrl, options);
      var data = res.data;
      var estado = data.estado;

      if (estado) {
        if (data.datos.usu_estado == 2) {
          let formErrors = {};

          formErrors["usuarioErr"] = "Paciente eliminado de HoraSalud.";
          this.setState({ formErrors: formErrors });
        } else if (data.datos.usu_estado == 0) {
          let formErrors = {};

          formErrors["usuarioErr"] = "Paciente no inscrito en HoraSalud.";
          this.setState({ formErrors: formErrors });
        } else {
          var usuId = data.datos.usu_id;
          this.setState({ usuId: usuId });
          formIsValid = usuId;
        }
      } else {
        let formErrors = {};

        formErrors["usuarioErr"] = res.data.mensaje;
        this.setState({ formErrors: formErrors });
      }
    }
    return formIsValid;
  };

  traerEspecialidades(proId) {
    const apiUrl = global.config.modulos.profesionales + "especialidades_por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var datos = res.data.datos;
        this.setState({
          especialidades: datos,
        });
      });
  }

  traerRendimientos(espid) {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_rendimiento/" + espid;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          rendimientos: res.data,
        });
      } else {
        this.setState({
          rendimientos: [
            {
              esr_rendimiento: 15,
            },
            {
              esr_rendimiento: 20,
            },
            {
              esr_rendimiento: 30,
            },
            {
              esr_rendimiento: 40,
            },
            {
              esr_rendimiento: 45,
            },
            {
              esr_rendimiento: 60,
            },
          ],
        });
      }
    });
  }

  render() {
    const { usuarioErr, especialidadErr, rendimientoErr, motivoErr } = this.state.formErrors;

    const cambioTipoAtencion = (event) => {
      this.setState({
        rendimiento: "",
        especialidad: event.target.value,
      });
      this.traerRendimientos(event.target.value);
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <h2>Sobrecupo</h2>
            </GridItem>
            <GridItem xs={6} style={{ marginTop: 30 }}>
              <InputLabel>Rut</InputLabel>
              <input type="text" name="rut" value={this.state.rut} placeholder="Ejemplo 12345678-9" className={"inputTextAgendar"} maxLength={12} onChange={this.cambiarValores} />
              {usuarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{usuarioErr}</div>}
            </GridItem>
            <GridItem xs={6} style={{ marginTop: 30 }}>
              <InputLabel>Tipo de atención</InputLabel>
              <Select name="especialidad" value={this.state.especialidad} displayEmpty className={"inputTextAgendar"} onChange={cambioTipoAtencion}>
                <MenuItem value="" disabled>
                  <em>Selecciona</em>
                </MenuItem>

                {this.state.especialidades.map((esp, index) => (
                  <MenuItem value={esp.esp_id} key={index}>
                    {esp.esp_nombre}
                  </MenuItem>
                ))}
              </Select>
              {especialidadErr && <div style={{ color: "red", paddingBottom: 10 }}>{especialidadErr}</div>}
            </GridItem>
            <GridItem xs={6} style={{ marginTop: 10 }}>
              <InputLabel>Duración de atención</InputLabel>
              <Select name="rendimiento" value={this.state.rendimiento} displayEmpty className={"inputTextAgendar"} onChange={this.cambiarValores}>
                <MenuItem value="" disabled>
                  <em>Selecciona</em>
                </MenuItem>

                {this.state.rendimientos.map((mb, index) => (
                  <MenuItem value={mb.esr_rendimiento} key={index}>
                    {mb.esr_rendimiento}
                  </MenuItem>
                ))}
              </Select>
              {rendimientoErr && <div style={{ color: "red", paddingBottom: 10 }}>{rendimientoErr}</div>}
            </GridItem>
            <GridItem xs={6} style={{ marginTop: 10 }}>
              <InputLabel>Hora de atención</InputLabel>
              <Select name="hora" value={this.state.hora} displayEmpty className={"inputTextAgendar"} style={{ width: "auto" }} onChange={this.cambiarValores}>
                {this.state.horas.map((mb, index) => (
                  <MenuItem value={mb} key={index}>
                    {mb}
                  </MenuItem>
                ))}
              </Select>
              :
              <Select name="minuto" value={this.state.minuto} displayEmpty className={"inputTextAgendar"} style={{ width: "auto" }} onChange={this.cambiarValores}>
                {this.state.minutos.map((mb, index) => (
                  <MenuItem value={mb} key={index}>
                    {mb}
                  </MenuItem>
                ))}
              </Select>
            </GridItem>
            <GridItem xs={6} style={{ marginTop: 10 }}>
              <InputLabel>Motivo</InputLabel>
              <input type="text" id="motivoCreacion" name="motivoCreacion" value={this.state.motivoCreacion} placeholder="" className={"inputTextAgendar"} onChange={this.cambiarValores} />
              {motivoErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoErr}</div>}
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 10 }}>
              <Button variant="contained" className="btnCargaHorario fondoVerde" style={{ color: "#fff" }} onClick={this.crearConsulta}>
                Crear sobrecupo
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
