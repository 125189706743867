import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { socketChat, socketSesion, socketNotificacion } from "./service/socket";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { onMessage } from "firebase/messaging";
import { fetchToken, messaging } from "./firebase";

import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Upperbar from "./components/upperbar/Upperbar";
import Bottombar from "./components/bottombar/Bottombar";
import axios from "axios";

import VideoLlamadaFlutter from "./views/VideoLlamada/VideoLlamadaFlutter";
import VideoLlamadaGrupal from "./views/VideoLlamada/VideoLlamadaGrupal";
import Pacientes from "./views/VideoLlamada/Pacientes";

import Usuarios from "./views/Usuarios/BuscarUsuario";
import BuscarProfesional from "./views/Profesionales/BuscarProfesional";
import Teleconsulta from "./views/Profesionales/ListaTeleconsulta";
import Horarios from "./views/Profesionales/Horarios";
import PacientesPro from "./views/Profesionales/Pacientes";

import CambiarClave from "./views/Perfil/CambiarClave";
import Vacunatorio from "./views/Vacunatorio/MenuVacunatorio";
import ListaVacunacionCovid from "./views/Vacunatorio/ListaVacunacionCovid";
import ListaVacunacionInfluenza from "./views/Vacunatorio/ListaVacunacionInfluenza";
import ListaAnulaciones from "./views/Anulaciones/ListaAnulaciones";
import ListaAnulacionesInternas from "./views/Anulaciones/ListaAnulacionesInternas";
import ListaBloqueos from "./views/Bloqueos/ListaBloqueos";
import Demanda from "./views/Demanda/GestionDemanda";
import GestionNoCovid from "./views/GestionNoCovid/GestionGeneral";
import GestionCovid from "./views/GestionCovid/GestionGeneral";
import GestionPlanContigo from "./views/GestionPlanContigo/GestionGeneral";
import GestionPlanContigoSalud from "./views/GestionPlanContigoSalud/GestionGeneral";
import GestionApoyoSocial from "./views/GestionApoyoSocial/GestionGeneral";
import CargarCiclos from "./views/CargaMasiva/CargarCiclos";
import CargarBeneficios from "./views/CargaMasiva/CargarBeneficios";
import CargarCondiciones from "./views/CargaMasiva/CargarCondiciones";
import EliminarCondiciones from "./views/CargaMasiva/EliminarCondiciones";
import CargarReservasExternas from "views/CargaMasiva/CargarReservasExternas";
import CargarFallecidos from "./views/CargaMasiva/CargarFallecidos";
import Recuperar from "./views/Login/Recuperar";
import EliminarCiclos from "./views/CargaMasiva/EliminarCiclos";
import EliminarBeneficios from "./views/CargaMasiva/EliminarBeneficios";
import MenuReservaExterna from "./views/GestionReservaExterna/MenuReservaExterna";
import CargaMasivaRecordatorios from "./views/GestionReservaExterna/CargaMasivaRecordatorios";
import GestionReservaExterna from "./views/GestionReservaExterna/GestionReservaExterna";
import CargarUsuarios from "./views/CargaMasiva/CargarUsuarios";

import Login from "./views/Login/Login";
import LoginSalud from "./views/Login/Login_salud";
import LoginDepartamento from "./views/Login/Login_departamento";
import LoginMedico from "./views/Login/Login_medico";
import VistaNSP from "./views/Reportes/VistaNSP";

import ListaPoliconsultantes from "./views/Reportes/ListaPoliconsultantes";
import PasaNumeros from "./views/Numeros/PasaNumeros1";
import AdminTotem from "./views/AdminTotem/Administrar";
import Totems from "./views/AdminTotem/Totems";
import AdminTotemPrincipal from "./views/AdminTotem/Totems";

import GraficoApps from "./views/Graficos/App";
import MenuVacunas from "./views/Vacunas/MenuVacunas";
import MenuVacunasInfluenza from "./views/Vacunas_influenza/MenuVacunas";

import SinInternet from "./components/Helpers/SinInternet";
import Cargando from "./components/Helpers/Cargando";
import LoginTimeout from "./components/idle/TiempoInactividad";
import Beneficios from "./views/Beneficios/Beneficios";
import RechazoDentalJunaeb from "./views/Beneficios/GestionLatenciaBene";
import GestionPrioritarios from "./views/GestionPrioritarios/GestionPrioritarios";
import MenuReporteriaTeleconsulta from "./views/Graficos/GraficosVideollamada/MenuReporteriaTeleconsulta";

import NoPercapitados from "./views/NoPercapitados/NoPercapitados";
import FiltroListaEspera from "./views/ListaDeEspera/FiltroListaEspera";
import CamaraHiperbarica from "./views/CamaraHiperbarica/FiltroListaEspera";

import Horas from "./views/Horas/VistaHoras";
import Difusiones from "./views/Difusiones/Difusiones";
import MenuMetas from "./views/Metas/MenuMetas";
import VistaOfertaYDemanda from "./views/Reportes/VistaOfertaYDemanda";
import CambioHoras from "./views/CambioHoras/MenuCambioHoras";
import sin_internet from "./assets/img/sin_internet.gif";

import GraficoOfertaRechazo from "./views/Graficos/Demanda/OfertaRechazo";
import GraficosGenerales from "./views/Graficos/Demanda/General";
import Ranking from "./views/Graficos/Demanda/Ranking.js";
import Nsp from "./views/Graficos/Demanda/Nsp.js";
import GraficosSector from "./views/Graficos/Demanda/GraficoSectores.js";
import GraficosProfesionales from "./views/Graficos/Demanda/GraficoProfesionales.js";
import TraerCargas from "./views/Graficos/Demanda/TablaCargaHorarios.js";
import DatosGenerales from "./views/Graficos/Demanda/DatosGenerales.js";
import DataImagenologia from "./views/Graficos/DescargarData/DataImagenologia.js";
import DataPurranque from "./views/Graficos/DescargarData/DataPurranque.js";
import GraficoLlamadas from "./views/Graficos/Demanda/GraficosCall.js";

import Solicitud_trabajo from "./views/Solicitud/Menu";
import Confirmacion_solicitud from "./views/Solicitud/Cesfam/HistorialNotificaciones";
import Administrar_solicitud from "./views/Solicitud/Administracion/Principal";
import Desarrollo_solicitud from "./views/Solicitud/Desarrollo/Desarrollo";
import Espera_produccion from "./views/Solicitud/Desarrollo/EnProduccion";

import GestionDespacho from "./views/GestionDespacho/GestionGeneral";

import Funcionarios from "./views/Funcionarios/Funcionarios";
import FuncionariosS from "./views/Funcionarios/FuncionariosSectores";
import Imagenologia from "./views/Imagenologia/Pacientes";
import HorarioImagenologia from "./views/Imagenologia/Horarios";

import HorarioEspecialidad from "./views/Especialidades/Horarios";
/* import Maps from "views/Maps/Maps"; */

import "font-awesome/css/font-awesome.min.css"; //import in react app

const App = (props) => {
  const isAuthUser = props.datos.isAuthUser;
  const loading = props.datos.loading;

  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const [isTokenFound, setTokenFound] = useState("");

  const updateNetwork = () => {
    if (!window.navigator.onLine) {
      setNetwork(false);
    } else {
      setNetwork(true);
    }
  };

  useEffect(() => {
    socketChat.on("connect", () => {
      console.log("Chat conectado");
    });
    socketSesion.on("connect", () => {
      console.log("Sesion conectado");
    });
    socketNotificacion.on("connect", () => {
      console.log("Notificación conectado");
    });

    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
  }, [socketChat, socketSesion, socketNotificacion]);

  onMessage(messaging, (message) => {
    NotificationManager.info(message.notification.body);
  });

  axios.interceptors.response.use(null, (error) => {
    if (error.config && error.response && error.response.status === 403 && !error.config.__isRetryRequest) {
      return updateToken().then((token) => {
        //console.log(token);

        error.config.__isRetryRequest = true;
        //error.config.headers.xxxx <= set the token
        // Here, the request data will be double stringified with qs.stringify,
        // potentially leading to 422 responses or similar.
        return axios.request(error.config);
      });
    }

    return Promise.reject(error);
  });

  const updateToken = () => {
    return axios
      .post(global.config.modulos.login + "refresh-token", {
        uspId: props.datos.usp,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        return res;
      });
  };

  return (
    <div className="app">
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <Router>
        <img src={sin_internet} hidden />
        {isOnline ? null : <SinInternet />}
        {loading ? <Cargando /> : null}
        {isAuthUser ? <Navbar /> : null}
        {isAuthUser ? <Upperbar /> : null}
        <div>
          <Switch>
            <Route path="/recuperar" exact component={Recuperar} />
            <Route path="/" exact component={isAuthUser ? Usuarios : Login} />
            <Route path="/salud" exact component={isAuthUser ? Usuarios : LoginSalud} />
            <Route path="/departamento" exact component={isAuthUser ? Usuarios : LoginDepartamento} />
            <Route path="/web_medico" exact component={isAuthUser ? Pacientes : LoginMedico} />

            {!isAuthUser && sessionStorage.getItem("isAuthUserVista") == 1 && <Redirect to="/" />}
            {!isAuthUser && sessionStorage.getItem("isAuthUserVista") == 2 && <Redirect to="/salud" />}
            {!isAuthUser && sessionStorage.getItem("isAuthUserVista") == 3 && <Redirect to="/departamento" />}
            {!isAuthUser && sessionStorage.getItem("isAuthUserVista") == 4 && <Redirect to="/web_medico" />}
            {!isAuthUser && <Redirect to="/" />}

            {isAuthUser ? (
              <>
                <Route path="/buscarRut" exact component={Usuarios} />
                <Route path="/buscarProfesional" exact component={BuscarProfesional} />
                <Route path="/listaAnulaciones" exact component={ListaAnulaciones} />
                <Route path="/listaAnulacionesInternas" exact component={ListaAnulacionesInternas} />
                <Route path="/vacunatorio" exact component={Vacunatorio} />
                <Route path="/listaBloqueos" exact component={ListaBloqueos} />
                <Route path="/demanda" exact component={Demanda} />
                <Route path="/gestionNoCovid" exact component={GestionNoCovid} />
                <Route path="/gestionCovid" exact component={GestionCovid} />
                <Route path="/cambiarClave" exact component={CambiarClave} />
                <Route path="/gestionPlanContigo" exact component={GestionPlanContigo} />
                <Route path="/gestionPlanContigoSalud" exact component={GestionPlanContigoSalud} />
                <Route path="/gestionApoyoSocial" exact component={GestionApoyoSocial} />
                <Route path="/cargarCiclos" exact component={CargarCiclos} />
                <Route path="/cargarBeneficios" exact component={CargarBeneficios} />
                <Route path="/cargarCondiciones" exact component={CargarCondiciones} />
                <Route path="/eliminarCondiciones" exact component={EliminarCondiciones} />
                <Route path="/cargarReservasExternas" exact component={CargarReservasExternas} />
                <Route path="/cargarFallecidos" exact component={CargarFallecidos} />
                <Route path="/eliminarCiclos" exact component={EliminarCiclos} />
                <Route path="/eliminarBeneficios" exact component={EliminarBeneficios} />
                <Route path="/menuReservaExterna" exact component={MenuReservaExterna} />
                <Route path="/noPercapitados" exact component={NoPercapitados} />
                <Route path="/vistaNSP" exact component={VistaNSP} />
                <Route path="/vistaOfertaDemanda" exact component={VistaOfertaYDemanda} />
                <Route path="/listaPoliconsultantes" exact component={ListaPoliconsultantes} />
                <Route path="/FiltroListaEspera" exact component={FiltroListaEspera} />
                <Route path="/CamaraHiperbarica" exact component={CamaraHiperbarica} />
                <Route path="/Beneficios" exact component={Beneficios} />
                <Route path="/cargaMasivaRecordatorios" exact component={CargaMasivaRecordatorios} />
                <Route path="/gestionReservaExterna" exact component={GestionReservaExterna} />
                <Route path="/listaVacunacionCovid" exact component={ListaVacunacionCovid} />
                <Route path="/listaVacunacionInfluenza" exact component={ListaVacunacionInfluenza} />
                <Route path="/traspaso" exact component={Horas} />
                <Route path="/listaTeleconsulta" exact component={Teleconsulta} />
                <Route path="/pasa_numero" exact component={PasaNumeros} />
                <Route path="/totems" exact component={Totems} />
                <Route path="/administrar_totem" exact component={AdminTotem} />
                <Route path="/administrar_totem_principal" exact component={AdminTotemPrincipal} />
                <Route path="/graficoAplicaciones" exact component={GraficoApps} />
                <Route path="/graficosVacunas" exact component={MenuVacunas} />
                <Route path="/graficosVacunasInfluenza" exact component={MenuVacunasInfluenza} />
                <Route path="/difusiones" exact component={Difusiones} />
                <Route path="/metas" exact component={MenuMetas} />
                <Route path="/gestionPrioritarios" exact component={GestionPrioritarios} />
                <Route path="/reporteriaVideoLlamadas" exact component={MenuReporteriaTeleconsulta} />
                <Route path="/videoLlamada" exact component={VideoLlamadaFlutter} />
                <Route path="/videoLlamadaGrupal" exact component={VideoLlamadaGrupal} />
                <Route path="/pacientes" exact component={Pacientes} />
                <Route path="/cambioHoras" exact component={CambioHoras} />
                <Route path="/horarios" exact component={Horarios} />
                <Route path="/cargarUsuarios" exact component={CargarUsuarios} />
                <Route path="/grafico_ofertaDemanda" exact component={GraficoOfertaRechazo} />
                <Route path="/graficos_generales" exact component={GraficosGenerales} />
                <Route path="/graficos_sectores" exact component={GraficosSector} />
                <Route path="/graficos_profesionales" exact component={GraficosProfesionales} />
                <Route path="/ranking" exact component={Ranking} />
                <Route path="/Nsp" exact component={Nsp} />
                <Route path="/TraerCargas" exact component={TraerCargas} />
                <Route path="/datosGenerales" exact component={DatosGenerales} />
                <Route path="/dataImagenologia" exact component={DataImagenologia} />
                <Route path="/dataPurranque" exact component={DataPurranque} />
                <Route path="/solicitud_trabajo" exact component={Solicitud_trabajo} />
                <Route path="/confirmacion_solicitud" exact component={Confirmacion_solicitud} />
                <Route path="/administrar_solicitud" exact component={Administrar_solicitud} />
                <Route path="/desarrollo_solicitud" exact component={Desarrollo_solicitud} />
                <Route path="/espera_produccion" exact component={Espera_produccion} />
                <Route path="/gestion_entrega" exact component={GestionDespacho} />
                <Route path="/funcionarios" exact component={Funcionarios} />
                <Route path="/funcionariosSec" exact component={FuncionariosS} />
                <Route path="/imagenologia" exact component={Imagenologia} />

                <Route path="/horarios_img" exact component={HorarioImagenologia} />
                <Route path="/rechazoJunaeb" exact component={RechazoDentalJunaeb} />
                <Route path="/graficoLlamadas" exact component={GraficoLlamadas} />
                <Route path="/horarios_esp" exact component={HorarioEspecialidad} />
                <Route path="/pacientes_pro" exact component={PacientesPro} />

                {/* <Route path="/maps/:comuna/:cesfam/:fecha/:tipo" render={(props) => <Maps comuna={props.match.params.comuna} cesfam={props.match.params.cesfam} fecha={props.match.params.fecha} tipo={props.match.params.tipo} />} /> */}
              </>
            ) : (
              <></>
            )}
          </Switch>
        </div>
        {isAuthUser ? <Bottombar /> : null}
      </Router>
      {isAuthUser ? <LoginTimeout /> : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    datos: state,
  };
}

//export default App;
export default connect(mapStateToProps, null)(App);
