import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";
import csvBeneficioTratamiento from "./../../assets/img/ejemplo_csv_beneficio_tratamiento.png";
import csvPrematuros from "../../assets/img/imgCargaMasiva/imgPrematuros.png";
import csvGeneral from "../../assets/img/imgCargaMasiva/2.png";
import csvCondicionEmbarazo from "./../../assets/img/imgCargaMasiva/cargaCondicionEmbarazo.png";
import csvCondicionDiscapacidad from "./../../assets/img/imgCargaMasiva/cargaCondicionDiscapacitado.png";
import csvCondicionCronico from "./../../assets/img/imgCargaMasiva/cargaCondicionCronico.png";
import csvCondicionCronicoCV from "./../../assets/img/imgCargaMasiva/cargaCondicionCronicoCV.png";
import csvCondicionCuidador from "./../../assets/img/imgCargaMasiva/cargaCondicionCuidador.png";
import { store } from "../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import signo from "./../../assets/img/signo.svg";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class CargarCondiciones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      condiciones: [],
      condicion: null,
      id_condicion: null,
      tipo_condicion: null,

      discapacidades: [],
      arr_discapacidades: [],
      datos: [],
      tipo_carga: "cargarCondiciones",
      herramientas: store.getState().herramientas,
      ultimaCarga: null,
      idCarga: null,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.traeCondiciones();
    this.traeDiscapacidades();
    this.titulo();
  }

  ultimaCarga() {
    axios
      .get(global.config.modulos.cargas_masivas + "condiciones/ultima_carga/", {
        headers: authHeader(),
        params: { id_usp: this.state.uspId, nombre_condicion: this.state.tipo_condicion, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          let fecha = res.data.datos.cam_fecha_registro.split("T");
          dia = String(fecha[0]).split("-");
          let dia = dia[0] + "-" + dia[1] + "-" + dia[2];
          this.setState({ ultimaCarga: "Última carga: " + dia + " a las " + fecha[1], idCarga: res.data.datos.cam_id });
        } else {
          this.setState({ ultimaCarga: null });
        }
      });
  }

  descargarExcel = (e) => {
    const url = global.config.modulos.cargas_masivas + "condiciones/excel_errores/?id_carga=" + this.state.idCarga;
    window.open(url, "_blank").focus();
  };

  traeCondiciones = () => {
    axios.get(global.config.modulos.cargas_masivas + "condiciones/listar_condiciones/", { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.setState({ condiciones: res.data.datos });
      }
    });
  };

  traeDiscapacidades = () => {
    axios.get(global.config.modulos.cargas_masivas + "condiciones/listar_discapacidades/", { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.setState({ discapacidades: res.data.datos });
      }
    });
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva <span title='Permite ingresar o actualizar a través de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  cambioValor = (event) => {
    const { name, value } = event.target;
    let condicion = value.split(";");
    let id_condicion = condicion[0];
    let tipo_condicion = condicion[1];
    this.setState(
      {
        [name]: value,
        id_condicion: id_condicion,
        tipo_condicion: tipo_condicion,
      },
      () => {
        this.ultimaCarga();
      }
    );
  };

  handleOnDrop = (data, file) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    this.setState({ datos: registros, archivo: file });
  };

  handleChange = (event) => {
    this.setState({ arr_discapacidades: event.target.value });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    this.cargarArchivo();
    const { archivo } = this.state;

    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.cargas_masivas + "condiciones/carga_masiva_condiciones/",
        {
          data: this.state.datos,
          uspId: this.state.uspId,
          tipo: this.state.id_condicion,
          discapacidades: this.state.arr_discapacidades,
          cesfam: this.state.cesfam,
          nombreArchivo: archivo.name,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("los registros se están ingresando, se le notificará cuando termine", "", 5 * 60 * 1000);
        } else {
          NotificationManager.warning(res.data.mensaje, "", 5 * 60 * 1000);
          if (res.data.errores.length > 0) {
            let mensaje = "";

            res.data.errores.forEach((reg) => {
              mensaje += "rut paciente: " + reg["rut_paciente"] + ", error: " + reg["mensaje"] + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "excepciones.txt";
            document.body.appendChild(element);
            element.click();
          }
        }
      });
  }

  cambiarVista = (e) => {
    const { name, value } = e.target;
    this.setState({ tipo_carga: value }, () => {
      this.props.history.push(this.state.tipo_carga);
    });
  };

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  cargarArchivo = async () => {
    const { archivo } = this.state;
    const data = new FormData();
    data.append("file", archivo);
    data.append("filename", archivo.name);
    await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_masiva", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return archivo.name;
  };

  render() {
    const { condiciones, condicion, datos, id_condicion, discapacidades, arr_discapacidades, ultimaCarga } = this.state;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={moduleCM.divIcon}>
                    <img className={moduleCM.imgCMciclos1} src={carga_ciclos} onClick={this.cargarCiclos} />

                    <img className={moduleCM.imgCMbeneficio} src={carga_beneficios} onClick={this.cargarBeneficios} />

                    <img className={moduleCM.imgCMfallecido} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                    {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro1} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                    {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  {ultimaCarga && (
                    <Grid item xs={12} sm={12}>
                      <Button
                        onClick={this.descargarExcel}
                        style={{
                          backgroundColor: "#bf3c50",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Descargar errores - {ultimaCarga}
                      </Button>
                    </Grid>
                  )}
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                      Inscribir/Desinscribir
                    </InputLabel>
                    <NativeSelect
                      className={moduleCM.selectCiclos}
                      value={this.state.tipo_carga}
                      onChange={this.cambiarVista}
                      inputProps={{
                        name: "tipo_carga",
                        id: "tipo_carga",
                      }}
                    >
                      <option value="cargarCondiciones">Inscribir condición</option>
                      <option selected value="eliminarCondiciones">
                        Desinscribir condición
                      </option>
                    </NativeSelect>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Condición
                        </InputLabel>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.condicion}
                          onChange={this.cambioValor}
                          inputProps={{
                            name: "condicion",
                            id: "condicion",
                          }}
                        >
                          <option value="">Seleccione condición</option>
                          {condiciones &&
                            condiciones.map((con, index) => (
                              <option value={con["con_id"] + ";" + con["con_nombre"]} key={index}>
                                {con["con_nombre"]}
                              </option>
                            ))}
                        </NativeSelect>
                      </Grid>
                      {id_condicion == 5 && (
                        <Grid item xs={6} sm={6}>
                          <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                            Discapacidades
                          </InputLabel>
                          <Select labelId="demo-mutiple-name-label" id="demo-mutiple-name" multiple value={arr_discapacidades} onChange={this.handleChange} input={<Input />} MenuProps={MenuProps}>
                            {discapacidades.map((reg) => (
                              <MenuItem key={reg.dis_id} value={reg.dis_id}>
                                {reg.dis_nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <br></br>
                  {id_condicion && (
                    <Grid item xs={12} sm={12}>
                      <span>Cargar CSV</span>
                      <div className={moduleCM.csvCargaMasiva}>
                        <CSVReader
                          onDrop={this.handleOnDrop}
                          onError={this.handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.handleOnRemoveFile}
                          config={{
                            header: true,
                            dynamicTyping: true,
                            skipEmptyLines: true,
                            transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                          }}
                        >
                          <div className={moduleCM.divSpanArchivo}>
                            <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                            <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                          </div>
                        </CSVReader>
                        <LinearProgress id="cargando" style={{ display: "none" }} />
                      </div>
                    </Grid>
                  )}
                  {datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  {id_condicion == 2 && <img className={moduleCM.imgEjemploCiclo} src={csvPrematuros} alt="csv" />}
                  {id_condicion == 3 && <img className={moduleCM.imgEjemploCiclo} src={csvCondicionEmbarazo} alt="csv" />}
                  {id_condicion == 9 && <img className={moduleCM.imgEjemploCiclo} src={csvCondicionCuidador} alt="csv" />}
                  {id_condicion == 5 && <img className={moduleCM.imgEjemploCiclo} src={csvCondicionDiscapacidad} alt="csv" />}
                  {id_condicion == 6 && this.state.cesfam != 29 && <img className={moduleCM.imgEjemploCiclo} src={csvCondicionCronico} alt="csv" />}
                  {id_condicion == 6 && this.state.cesfam == 29 && <img className={moduleCM.imgEjemploCiclo} src={csvCondicionCronicoCV} alt="csv" />}
                  {["4", "7", "8", "10", "11"].includes(id_condicion) && <img className={moduleCM.imgEjemploCiclo} src={csvGeneral} alt="csv" />}
                </Grid>
              </Grid>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
