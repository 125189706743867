import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import chileanRut from "chilean-rut";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { confirmAlert } from "react-confirm-alert"; // Import

import moment from "moment";
import "moment/locale/es";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalSobreCupo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,

      profesional: props.profesional,
      box: "",

      open: true,
      formErrors: [],
    };
  }

  componentDidMount() {}

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirExito = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarExito();
  };

  validaciones() {
    const { box } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (box == "") {
      formIsValid = false;
      formErrors["boxErr"] = "Escribe el box";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  registrarBox = () => {
    if (this.validaciones()) {
      axios
        .post(
          global.config.modulos.profesionales + "registrar_box/",
          {
            profesional: this.state.profesional,
            box: this.state.box,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          this.salirExito();
        });
    }
  };

  render() {
    const { boxErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="">
        <DialogContent>
          <GridItem xs={12} sm={12} md={12}>
            <h2>Box de atención</h2>
          </GridItem>
          <GridItem xs={12} style={{ marginTop: 30 }}>
            <input type="text" name="box" value={this.state.box} placeholder="" className={"inputTextAgendar"} onChange={this.cambiarValores} />
            {boxErr && <div style={{ color: "red", paddingBottom: 10 }}>{boxErr}</div>}
          </GridItem>
          <GridItem xs={12} style={{ marginTop: 10 }}>
            <Button variant="contained" className="btnCargaHorario fondoVerde" style={{ color: "#fff" }} onClick={this.registrarBox}>
              Asignar
            </Button>
          </GridItem>
        </DialogContent>
      </Dialog>
    );
  }
}
