import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { connect } from "react-redux";

import Button from "@material-ui/core/Button";

import axios from "axios";
import chileanRut from "chilean-rut";

import moment from "moment";
import "moment/locale/es";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import { store } from "../../store/index";
import { socketNotificacion } from "../../service/socket";
import { confirmAlert } from "react-confirm-alert";

import Tooltip from "@material-ui/core/Tooltip";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import icono_ver_mas from "assets/img/usuarios/icono_ver_mas.svg";
import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import estados_colores from "assets/img/estados_colores.svg";

import signo from "assets/img/signo.svg";

import Observacion from "components/Profesionales/ModalObservacion";
import MotivoReserva from "components/Usuarios/MotivoReserva";
import ModalBox from "./ModalBox";

import "assets/css/videollamada.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import imagenologia from "assets/css/imagenologia.module.css";

class Pacientes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proId: store.getState().id_medico,
      uspId: store.getState().usp,

      fechaSeleccionada: "",
      semanaFiltro: moment().format("GGGG-[W]WW"),
      color: "#1ac6c1",
      posicion: 0,

      imaId: 0,
      pacientes: [],
      box: "",

      observacion: false,
      motivoReserva: false,
      datosObs: null,
    };
  }

  componentDidMount() {
    this.titulo();
    this.traerSemanas(this.state.semanaFiltro);
    /*   socketNotificacion.on("confirmar_llegada", (data) => {
      this.traerSemanas(this.state.semanaFiltro);
    }); */
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Pacientes del día <span title=''><img src=" + signo + "></img></span>";
  };

  traerSemanas = (sem) => {
    var semana = sem;

    if (semana == "") {
      return;
    }

    var arrSemana = sem.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var colores = ["#1ac6c1", "#ea1877", "#7b37db", "#d625b4", "#1289db", "#24bde0"];

    this.traerDatos(inicioSemana, colores[0], 0);
  };

  traerDatos = async (fecha, color, posicion) => {
    this.setState({
      fechaSeleccionada: fecha,
      color: color,
      posicion: posicion,
    });
    fecha = moment(fecha).format("YYYY-MM-DD");

    var arrSemana = this.state.semanaFiltro.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var botonesTop = [];

    var colores = ["#1ac6c1", "#ea1877", "#7b37db", "#d625b4", "#1289db", "#24bde0"];
    var inicioSemana = moment(inicioSemana);
    for (var i = 0; i <= 5; i++) {
      if (i == posicion) {
        botonesTop.push(
          <button id={"btn" + i} className={imagenologia.botonL} style={{ background: colores[i], width: "11vw" }} onClick={this.traerDatos.bind(this, inicioSemana, colores[i], i)}>
            {inicioSemana.format("dddd") + " " + inicioSemana.format("DD")}
          </button>
        );
      } else {
        botonesTop.push(
          <button id={"btn" + i} className={imagenologia.botonL} style={{ background: colores[i] }} onClick={this.traerDatos.bind(this, inicioSemana, colores[i], i)}>
            {inicioSemana.format("ddd") + " " + inicioSemana.format("DD")}
          </button>
        );
      }

      inicioSemana = moment(inicioSemana).add(1, "day");
    }

    this.setState({
      botonesTop: botonesTop,
    });

    var datos = {
      fecha: fecha,
      pro_id: this.state.proId,
    };

    var res = await axios.get(global.config.modulos.agendamientos + "profesional_horario_por_fecha/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    var agenda = data.agenda;

    var tieneBox = data.tieneBox;
    var box = data.box;

    if (!tieneBox) {
      this.ingresarBox();
      return;
    }

    var tableBody = [];
    if (agenda.length > 0) {
      for (var i = 0; i < agenda.length; i++) {
        tableBody.push(
          <React.Fragment>
            <TableRow key={i} style={{ background: agenda[i]["estado_color"] }}>
              <TableCell align="center">{agenda[i]["hora"]}</TableCell>
              <TableCell align="center">{chileanRut.format(agenda[i]["usu_rut"], true)}</TableCell>
              <TableCell align="center">{agenda[i]["nombre_usuario"]}</TableCell>
              <TableCell align="center">
                <p>{agenda[i]["usu_nombre_social"] != null ? agenda[i]["usu_nombre_social"] : "--"}</p>
              </TableCell>
              <Tooltip title={"Edad: " + agenda[i]["edad"]} aria-label={""}>
                <TableCell align="center">{agenda[i]["usu_fecha_nacimiento"] != null ? agenda[i]["usu_fecha_nacimiento"] : "--"}</TableCell>
              </Tooltip>
              <TableCell align="center">{agenda[i]["nombre_cesfam"]}</TableCell>
              <TableCell align="center">{agenda[i]["esp_nombre"]}</TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    justifyContent: "center",
                  }}
                >
                  {agenda[i]["obse"]["observacion"] != null ? (
                    <React.Fragment>
                      <Tooltip title={<h4>ver observación</h4>}>
                        <div style={{ marginRight: "10px" }}>
                          <img class="mediumIcon" src={icono_ver_mas} onClick={this.observacion.bind(this, agenda[i]["obse"])}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Tooltip title={<h4>Ingresar observación</h4>}>
                        <div>
                          <img class="mediumIcon" src={icono_modificar} onClick={this.ingresarObservacion.bind(this, agenda[i]["age_horario"], agenda[i]["age_especialidad"], agenda[i]["usu_id"])}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  )}
                </div>
              </TableCell>

              <TableCell align="center">
                <div>
                  <Button onClick={agenda[i]["habilitarLlamado"] ? this.llamarPaciente.bind(this, agenda[i]["age_id"]) : null} className={agenda[i]["habilitarLlamado"] ? "fondoVerde" : "btnDisabled btnDisabledMed"} style={{ color: "#fff", width: "100%" }}>
                    Llamar al paciente
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      }
    } else {
      tableBody.push(
        <TableRow>
          <TableCell align="center" style={{ height: 250 }} colSpan={8}>
            No tiene horas
          </TableCell>
        </TableRow>
      );
    }

    var border = "3px solid " + color;
    var tabla = [];
    tabla.push(
      <TableContainer style={{ maxHeight: "46vh", border: border, borderRadius: "15px" }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Hora
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                RUT
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Nombre
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Nombre Social
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Fecha de Nacimiento
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Cesfam
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Tipo de atención
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Observación
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
    );

    this.setState({
      tabla: tabla,
      box: box,
    });
  };

  observacion = (obs) => {
    this.setState({ observacion: true, datosObs: obs });
  };

  ocultarObservacion = (e) => {
    this.setState({ observacion: false });
  };

  ingresarObservacion = (horario, especialidad, usuId) => {
    axios
      .get(global.config.modulos.agendamientos + "motivos_reserva_horario/", {
        headers: authHeader(),
        params: {
          especialidad: especialidad,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            motivos: res.data.motivos,
            horarioReserva: horario,
            cesfamReserva: this.state.cesfam,
            usuarioReserva: usuId,
            tipoReserva: res.data.tipo,
            motivoReserva: true,
          });
        }
      });
  };

  ocultarMotivoReserva = () => {
    this.setState({
      motivoReserva: false,
    });
  };

  ocultarMotivoReservaCreada = () => {
    this.setState({
      motivoReserva: false,
    });
  };

  llamarPaciente = (agenda) => {
    let apiUrl = global.config.modulos.totem + "llamar_profesional/";
    const options = {
      method: "POST",
      data: {
        age_id: agenda,
        box: this.state.box,
      },
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {});
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  ingresarBox = () => {
    this.setState({
      ingresarBox: true,
    });
  };

  ocultarBox = () => {
    this.setState({
      ingresarBox: false,
    });
  };

  ocultarBoxCreado = () => {
    this.setState(
      {
        ingresarBox: false,
      },
      () => this.traerDatos(this.state.fechaSeleccionada, this.state.color, this.state.posicion)
    );
  };

  render() {
    const cambiarSemana = (event) => {
      var semanaFiltro = event.target.value;
      if (semanaFiltro != "") {
        this.setState(
          {
            semanaFiltro: semanaFiltro,
            tabla: "",
          },
          () => {
            this.traerSemanas(semanaFiltro);
          }
        );
      }
    };

    return (
      <>
        <article className="middle-area">
          <GridContainer>
            <GridItem xs={6}>
              <InputLabel>Semana del horario</InputLabel>
              <TextField id="semanaFiltro" name="semanaFiltro" type="week" value={this.state.semanaFiltro} onChange={cambiarSemana} />
            </GridItem>

            <GridItem xs={6} style={{ textAlign: "end" }}>
              <img src={estados_colores} style={{ width: "29vw" }} />
            </GridItem>
          </GridContainer>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              {this.state.botonesTop}
            </div>

            {this.state.tabla}
          </div>
        </article>

        {this.state.observacion ? <Observacion ocultar={this.ocultarObservacion} observacion={this.state.datosObs}></Observacion> : null}
        {this.state.motivoReserva ? <MotivoReserva motivos={this.state.motivos} horarioReserva={this.state.horarioReserva} usuarioReserva={this.state.usuarioReserva} tipoReserva={this.state.tipoReserva} ocultarAgendar={this.ocultarMotivoReservaCreada} ocultar={this.ocultarMotivoReserva} omitir={false} /> : null}
        {this.state.ingresarBox ? <ModalBox profesional={this.state.proId} ocultarExito={this.ocultarBoxCreado} ocultar={this.ocultarBox} /> : null}
      </>
    );
  }
}
export default connect()(Pacientes);
