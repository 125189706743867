import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import { confirmAlert } from "react-confirm-alert"; // Import
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import axios from "axios";
import moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import { NotificationManager } from "react-notifications";

import icono_reservar from "assets/img/usuarios/icono_reservar.svg";
import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import iconoEliminar from "assets/img/usuarios/icono_eliminar.svg";
import iconoAsistencia from "assets/img/asistencia.svg";
import anunciarLlegada from "assets/img/usuarios/icono_anunciar_llegada.svg";
import icono_ver_mas from "assets/img/usuarios/icono_ver_mas.svg";

import CambiarRut from "./ModalCambiarRut.js";
import AgendarHora from "./../Agendar/ModalAgendarHora.js";
import CambiarBloqueo from "./ModalCambioBloqueo.js";
import CambiarEspecialidad from "./ModalCambioEsp.js";
import AnularHora from "../Usuarios/AnularHora";
import CompletarFolio from "../Usuarios/CompletarFolio";
import SobreCupo from "./ModalSobrecupo.js";
import Observacion from "./ModalObservacion.js";
import MotivoReserva from "components/Usuarios/MotivoReserva";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "assets/css/profesionales.css";

import CrearHorarioBloqueo from "components/Profesionales/CrearHorarioBloqueo";

export default class HorarioDiarioProfesional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabla: "",

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      proId: this.props.profesional,
      especialidad: this.props.especialidad,
      fechaHorario: this.props.fechaHorario,

      arregloHorario: [],
      horarios: [],
      totalHorasBloqueadas: 0,

      motivosBloqueo: [],
      motivoBloqueo: "0",
      motivoBloqueoTxt: "",

      nombreProfesional: "",
      nombreEspecialidad: "",
      horarioAgendar: "",
      agendaReservada: "",
      usuarioReserva: "",

      siglasHerramienta: ["H17", "H18"],
      herramientas: store.getState().herramientas,

      open: true,
      existenHorasReservadas: false,
      existenHorasBloqueadas: false,
      mostrarMotivoTxt: false,
      seleccionado: false,
      crearHorarioBloqueo: false,
      agendarHora: false,
      cambiarBloqueo: false,
      cambiarEspecialidad: false,
      cambiarRut: false,
      observacion: false,
      motivoReserva: false,
      datosObs: null,
    };
  }

  componentDidMount() {
    this.horario();
    this.traerMotivosBloqueo();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlertaCargadoExito() {
    NotificationManager.success("Horario creado con éxito");
  }

  bloquearHorarioConfirmar = () => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    if (this.state.arregloHorario.length == 0) {
      NotificationManager.warning("Debes seleccionar una o mas horas para Bloquear");
    } else if (motivoBloqueo == "0") {
      NotificationManager.warning("Debe Seleccionar un motivo");
    } else if (motivoBloqueo == "otro" && motivoBloqueoTxt == "") {
      NotificationManager.warning("Debe escribir el motivo");
    } else {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ que desea bloquear las horas seleccionadas?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.revisarHorario(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  desbloquearHorarioConfirmar = () => {
    if (this.state.arregloHorario.length == 0) {
      NotificationManager.warning("Debes seleccionar una o mas horas para Desbloquear");
    } else {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ que desea desbloquear las horas seleccionadas?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.desbloquearHorarios(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  eliminarHorarioConfirmar = () => {
    if (this.state.arregloHorario.length == 0) {
      NotificationManager.warning("Debes seleccionar una o mas horas para eliminar");
    } else {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ que desea eliminar las horas seleccionadas?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.eliminarHorarios(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  agendarHora = (horId, especialidad) => {
    this.setState({
      agendarHora: true,
      horarioAgendar: horId,
      nombreEspecialidad: especialidad,
    });
  };

  cambiarBloqueo = () => {
    if (this.state.arregloHorario.length == 0) {
      NotificationManager.warning("Debes seleccionar una o mas horas para Bloquear");
    } else {
      this.setState({
        cambiarBloqueo: true,
      });
    }
  };

  horario = () => {
    var data = {
      fecha: this.state.fechaHorario,
      cesfam: this.state.cesfam,
      profesional: this.state.proId,
      especialidad: this.state.especialidad,
    };

    let apiUrl = global.config.modulos.agendamientos + "horarios_profesional_especialidad/";

    const options = {
      method: "GET",
      params: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;

      this.setState({
        nombreProfesional: data.nombreProfesional,
        nombreEspecialidad: data.especialidad,
      });

      var tabla = [];
      tabla.push(
        <TableHead>
          <TableRow className={"tablaRow "}>
            <TableCell className={"tablaRow cellInicio"} key={0}>
              Tipo de atención
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Hora
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Rut paciente
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Nombre paciente
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Estado
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Canal
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Funcionario
            </TableCell>
            <TableCell className={"tablaRow"} key={0}>
              Motivo
            </TableCell>
            <TableCell className={"tablaRow cellTermino"} key={0}>
              Observación
            </TableCell>
            <TableCell className={"tablaRow cellTermino"} key={0}>
              Acción
            </TableCell>
          </TableRow>
        </TableHead>
      );

      var rows = [];

      var horario = res.data.datos;
      var conteoHorario = horario.length;
      for (var j = 0; j < conteoHorario; j++) {
        var obs = horario[j].obs;
        var ageId = horario[j].age_id;
        var horId = horario[j].hor_id;
        var espId = horario[j].esp_id;
        var tipoAtencion = horario[j].tipo_atencion;
        var hora = horario[j].hora;

        var usuId = horario[j].usu_id;
        var rutUsuario = horario[j].usu_rut;
        var nombreUsuario = horario[j].usu_nombre;

        var horEstado = horario[j].estado_hora;
        var funcionario = horario[j].funcionario;
        var motivo = horario[j].motivo;
        var fechaBloqueo = horario[j].fecha_bloqueo;
        var modalidad = horario[j].modalidad;
        var canal = horario[j].canal;

        var cell = [];
        cell.push(<TableCell className={"tablaRow"}>{tipoAtencion}</TableCell>);
        cell.push(<TableCell className={"tablaRow"}>{hora}</TableCell>);
        cell.push(<TableCell className={"tablaRow"}>{rutUsuario}</TableCell>);
        cell.push(<TableCell className={"tablaRow"}>{nombreUsuario}</TableCell>);

        if (modalidad != "--") {
          cell.push(
            <TableCell
              className={"tablaRow"}
              style={{
                whiteSpace: "pre-line",
              }}
            >
              <Tooltip
                title={
                  <React.Fragment>
                    <div>
                      <label
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {modalidad}
                      </label>
                    </div>
                  </React.Fragment>
                }
                placement="right"
                interactive
                arrow
                className="tooltipHorario"
              >
                <a title="Paciente" className="mr-1" id={"Popover-" + horId}>
                  {horEstado}
                </a>
              </Tooltip>
            </TableCell>
          );
        } else {
          cell.push(
            <TableCell
              className={"tablaRow"}
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {horEstado}
            </TableCell>
          );
        }

        if (canal != "--") {
          cell.push(
            <TableCell className={"tablaRow"}>
              <Tooltip
                title={
                  <React.Fragment>
                    <div>
                      {funcionario != "--" && (
                        <label>
                          {"Funcionario: "}
                          {funcionario}
                        </label>
                      )}
                      <br></br>
                      <label>
                        {"Fecha de reserva: "} {fechaBloqueo}
                      </label>
                    </div>
                  </React.Fragment>
                }
                placement="right"
                interactive
                arrow
                className="tooltipHorario"
              >
                <a title="Paciente" className="mr-1" id={"Popover-" + horId}>
                  {canal}
                </a>
              </Tooltip>
            </TableCell>
          );
        } else {
          cell.push(<TableCell className={"tablaRow"}>{canal}</TableCell>);
        }

        if (horEstado == "Bloqueada") {
          cell.push(
            <TableCell className={"tablaRow"}>
              <Tooltip
                title={
                  <React.Fragment>
                    <div>
                      <label>{"Hora bloqueada"}</label>
                      <br></br>
                      <label>{fechaBloqueo}</label>
                    </div>
                  </React.Fragment>
                }
                placement="right"
                interactive
                arrow
                className="tooltipHorario"
              >
                <a title="Paciente" className="mr-1" id={"Popover-" + horId}>
                  {funcionario}
                </a>
              </Tooltip>
            </TableCell>
          );
        } else {
          cell.push(<TableCell className={"tablaRow"}>{funcionario}</TableCell>);
        }

        cell.push(<TableCell className={"tablaRow"}>{motivo}</TableCell>);

        let fechaAtencion = moment(this.state.fechaHorario).format("YYYY-MM-DD") + " " + hora + ":00";

        if (fechaAtencion > moment().format("YYYY-MM-DD HH:mm:ss")) {
          if (horEstado == "Bloqueada") {
            this.setState({ existenHorasBloqueadas: true });
          }

          var reservar = 0;
          if (this.state.herramientas.includes(this.state.siglasHerramienta[0])) {
            if (horEstado.includes("Disponible") || horEstado.includes("En pausa")) {
              reservar = 1;
            }
          } else if (this.state.herramientas.includes(this.state.siglasHerramienta[1])) {
            if (horEstado.includes("Disponible")) {
              reservar = 1;
            }
          } else {
            reservar = 0;
          }

          if (reservar == 1) {
            cell.push(
              <TableCell align="justify">
                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    justifyContent: "center",
                  }}
                >
                  <React.Fragment>
                    <div>--</div>
                  </React.Fragment>
                </div>
              </TableCell>
            );

            cell.push(
              <TableCell align="justify">
                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    justifyContent: "center",
                  }}
                >
                  <React.Fragment>
                    <Tooltip title={"Cambiar tipo de atención"}>
                      <div style={{ marginRight: "10px" }}>
                        <img class="imagenBotonReservarHorario" src={icono_modificar} aria-label="Cambiar tipo de atención" title="Cambiar tipo de atención" onClick={this.cambiarEspecialidad.bind(this, horId)}></img>
                      </div>
                    </Tooltip>
                  </React.Fragment>
                  <React.Fragment>
                    <Tooltip title={"Reservar una hora"}>
                      <div style={{ marginRight: "10px" }}>
                        <img class="imagenBotonReservarHorario" src={icono_reservar} aria-label="Reservar una hora" title="Reservar una hora" onClick={this.agendarHora.bind(this, horId, tipoAtencion)}></img>
                      </div>
                    </Tooltip>
                  </React.Fragment>
                  <React.Fragment>
                    <div style={{ marginRight: "10px" }}>
                      <a id={"seleccionar" + horId} className={"botonesCargaMasiva porSeleccionar"} style={{ float: "right" }} onClick={this.seleccionar.bind(this, "seleccionar" + horId, horId)}>
                        <label className={"textoBotonesCarga"}> Seleccionar </label>
                      </a>
                    </div>
                  </React.Fragment>
                </div>
              </TableCell>
            );
          } else {
            if (horEstado == "Reservada" || horEstado == "Confirmada") {
              this.setState({ existenHorasReservadas: true });
              cell.push(
                <TableCell align="justify">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {obs["observacion"] != null ? (
                      <React.Fragment>
                        <Tooltip title={"ver observación"}>
                          <div style={{ marginRight: "10px" }}>
                            <img class="mediumIcon" src={icono_ver_mas} onClick={this.observacion.bind(this, obs)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Tooltip title={"Ingresar observación"}>
                          <div>
                            <img class="mediumIcon" src={icono_modificar} onClick={this.ingresarObservacion.bind(this, horId, espId, usuId)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    )}
                  </div>
                </TableCell>
              );

              cell.push(
                <TableCell align="justify">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <React.Fragment>
                      <Tooltip title={"Cambiar paciente"}>
                        <div style={{ marginRight: "10px" }}>
                          <img class="imagenBotonReservarHorario" src={icono_modificar} aria-label="Cambiar paciente" title="Cambiar paciente" onClick={this.cambiarRut.bind(this, horId, tipoAtencion, ageId)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>

                    <React.Fragment>
                      <Tooltip title={"Anular la hora del paciente"}>
                        <div style={{ marginRight: "10px" }}>
                          <img class="imagenBotonReservarHorario" src={iconoEliminar} aria-label="Anular la hora del paciente" title="Anular la hora del paciente" onClick={this.anularHora.bind(this, ageId, usuId)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>

                    {this.state.herramientas.includes("H59") && (
                      <React.Fragment>
                        <Tooltip title={"Asistencia"}>
                          <div style={{ marginRight: "10px" }}>
                            <img class="imagenBotonReservarHorario" src={iconoAsistencia} aria-label="Asistencia" title="Asistencia" onClick={this.asistencia.bind(this, ageId)}></img>{" "}
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    )}

                    {this.state.herramientas.includes("H73") && fechaAtencion <= moment().add(6, "hours").format("YYYY-MM-DD HH:mm:ss").toString() ? (
                      <React.Fragment>
                        <Tooltip title={"Anunciar llegada del paciente"} aria-label={"Anunciar llegada del paciente"}>
                          <div style={{ marginRight: "10px" }}>
                            <img className="imagenBotonReservarHorario" src={anunciarLlegada} onClick={this.mostrarModalAnunciarLlegada.bind(this, ageId, espId, usuId)} />
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    ) : null}

                    <React.Fragment>
                      <div style={{ marginRight: "10px" }}>
                        <a id={"seleccionar" + horId} className={"botonesCargaMasiva porSeleccionar"} style={{ float: "right" }} onClick={this.seleccionar.bind(this, "seleccionar" + horId, horId)}>
                          <label className={"textoBotonesCarga"}> Seleccionar </label>
                        </a>
                      </div>
                    </React.Fragment>
                  </div>
                </TableCell>
              );
            } else {
              cell.push(
                <TableCell align="justify">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <React.Fragment>
                      <div>--</div>
                    </React.Fragment>
                  </div>
                </TableCell>
              );

              cell.push(
                <TableCell align="justify">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.herramientas.includes("H59") && horEstado != "Bloqueada" && ageId != null && (
                      <React.Fragment>
                        <Tooltip title={"Asistencia"}>
                          <div style={{ marginRight: "10px" }}>
                            <img class="imagenBotonReservarHorario" src={iconoAsistencia} aria-label="Asistencia" title="Asistencia" onClick={this.asistencia.bind(this, ageId)}></img>{" "}
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    )}

                    <React.Fragment>
                      <div style={{ marginRight: "10px" }}>
                        <a id={"seleccionar" + horId} className={"botonesCargaMasiva porSeleccionar"} style={{ float: "right" }} onClick={this.seleccionar.bind(this, "seleccionar" + horId, horId)}>
                          <label className={"textoBotonesCarga"}> Seleccionar </label>
                        </a>
                      </div>
                    </React.Fragment>
                  </div>
                </TableCell>
              );
            }
          }
        } else {
          cell.push(
            <TableCell align="justify">
              <div
                style={{
                  display: "flex",
                  marginTop: "5px",
                  justifyContent: "center",
                }}
              >
                <React.Fragment>
                  <div>--</div>
                </React.Fragment>
              </div>
            </TableCell>
          );

          cell.push(
            <TableCell align="justify">
              <div
                style={{
                  display: "flex",
                  marginTop: "5px",
                  justifyContent: "center",
                }}
              >
                {this.state.herramientas.includes("H73") && horEstado != "Bloqueada" && ageId != null && fechaAtencion >= moment().subtract(6, "minutes").format("YYYY-MM-DD HH:mm:ss").toString() ? (
                  <React.Fragment>
                    <Tooltip title={"Anunciar llegada del paciente"} aria-label={"Anunciar llegada del paciente"}>
                      <div>
                        <img className="imagenBotonReservarHorario" src={anunciarLlegada} onClick={this.mostrarModalAnunciarLlegada.bind(this, ageId, espId, usuId)} />
                      </div>
                    </Tooltip>
                  </React.Fragment>
                ) : null}

                {this.state.herramientas.includes("H59") && horEstado != "Bloqueada" && ageId != null ? (
                  <React.Fragment>
                    <Tooltip title={""}>
                      <div>
                        <img class="imagenBotonReservarHorario" src={iconoAsistencia} aria-label="Asistencia" title="Asistencia" onClick={this.asistencia.bind(this, ageId)}></img>
                      </div>
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>--</div>
                  </React.Fragment>
                )}
              </div>
            </TableCell>
          );
        }

        rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);

        this.state.horarios.push(horId);
      }

      tabla.push(<TableBody>{rows}</TableBody>);

      this.setState({
        tabla: (
          <Table stickyHeader size="small" aria-label="a dense table" className="bordeTabla">
            {tabla}
          </Table>
        ),
      });
    });
  };

  asistencia = (ageId) => {
    confirmAlert({
      title: "",
      message: "¿El paciente ha asistido a su hora médica?",
      buttons: [
        {
          label: "No realizada",
          className: "background-rojo",
          onClick: () => this.cambiarEstadoAgenda(ageId, 12),
        },
        {
          label: "No se presentó",
          className: "btnCancelar",
          onClick: () => this.cambiarEstadoAgenda(ageId, 9),
        },
        {
          label: "Sí, se presentó",
          className: "btnAceptar",
          onClick: () => this.cambiarEstadoAgenda(ageId, 7),
        },
      ],
    });
  };

  cambiarEstadoAgenda = async (ageId, estadoAgenda) => {
    const datos = { agenda: ageId, estadoHora: estadoAgenda, uspId: this.state.uspId };
    await axios.post(global.config.modulos.agendamientos + "cambio_estado_agenda/", datos, { headers: authHeader() });
    this.horario();
  };

  seleccionar(idDiv, horId) {
    const { arregloHorario } = this.state;

    var link = document.getElementById(idDiv);

    if (arregloHorario.includes(horId)) {
      link.innerHTML = '<label class="textoBotonesCarga">Seleccionar</label>';
      link.style.backgroundColor = "#fff0";
      link.style.borderColor = "#000";
      var index = arregloHorario.indexOf(horId);
      arregloHorario.splice(index, 1);
    } else {
      arregloHorario.push(horId);
      link.innerHTML = '<a class=""><label style="color:#ffffff;cursor: pointer;">Seleccionado</label></a>';
      link.style.backgroundColor = "#26a530";
      link.style.borderColor = "#26a530";
    }
  }

  horas_libres_bloqueo = async (totalConAgenda) => {
    var estado = false;
    var data = {
      horarios: this.state.arregloHorario,
      total: totalConAgenda,
    };

    let apiUrl = global.config.modulos.agendamientos + "horas_libres_bloqueo/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    const res = await axios(apiUrl, options);
    estado = res.data["estado"];

    return estado;
  };

  revisarHorario = async () => {
    var data = {
      horarios: this.state.arregloHorario,
    };

    let apiUrl = global.config.modulos.agendamientos + "horario_con_agenda/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    const res = await axios(apiUrl, options);
    if (res.data["estado"]) {
      var horasConAgenda = res.data["horas_con_agenda"];
      if (horasConAgenda > 0) {
        confirmAlert({
          title: "",
          message: "¿Desea crear horas para reagendar a los usuarios? se necesitan " + horasConAgenda + " hrs.",
          buttons: [
            {
              label: "Sí",
              onClick: () => this.crearHorarios(horasConAgenda),
            },
            {
              label: "No",
              onClick: () => this.noCrearHoras(horasConAgenda),
            },
          ],
        });
      } else {
        this.bloquearHorarios();
      }
    } else {
      NotificationManager.error(res.data["mensaje"]);
    }
  };

  noCrearHoras = async (horasConAgenda) => {
    var horas_libres_bloqueo = await this.horas_libres_bloqueo(horasConAgenda);
    if (horas_libres_bloqueo) {
      confirmAlert({
        title: "",
        message: "Se utilizarán las horas futuras, por lo tanto quedarán menos horas para ofrecer a sus usuarios.",
        buttons: [
          {
            label: "Continuar con el bloqueo",
            onClick: () => this.reservarHorasBloqueo(horasConAgenda),
          },
          {
            label: "Cargar horas",
            onClick: () => this.crearHorarios(horasConAgenda),
          },
        ],
      });
    } else {
      confirmAlert({
        title: "",
        message: "Las horas disponibles no permitirá que todos los usuarios reserven, dejándolos sin atención. \n" + "Estas seguro que quieres continuar sin habilitar nuevas horas?",
        buttons: [
          {
            label: "Continuar con el bloqueo",
            onClick: () => this.reservarHorasBloqueo(horasConAgenda),
          },
          {
            label: "Bloquear en otro momento",
          },
        ],
      });
    }
  };

  crearHorarios = (horasConAgenda) => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    var motivoBloqueoHora = "";
    if (motivoBloqueo == "otro") {
      motivoBloqueoHora = motivoBloqueoTxt;
    } else {
      motivoBloqueoHora = motivoBloqueo;
    }

    let apiUrl = global.config.modulos.agendamientos + "eliminar_temporal_semanal/?uspId=" + this.state.uspId;
    axios(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        horasConAgenda: horasConAgenda,
        crearHorarioBloqueo: true,
        motivoBloqueoHora: motivoBloqueoHora,
      });
    });
  };

  reservarHorasBloqueo = (horasConAgenda) => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    var motivoBloqueoHora = "";
    if (motivoBloqueo == "otro") {
      motivoBloqueoHora = motivoBloqueoTxt;
    } else {
      motivoBloqueoHora = motivoBloqueo;
    }

    var data = {
      horarios: this.state.arregloHorario,
      motivoBloqueo: motivoBloqueoHora,
      canal: 5,
      uspId: this.state.uspId,
      tipo: 1,
    };

    let apiUrl = global.config.modulos.agendamiento_celery + "reservar_horas_bloqueo/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      this.salir();
      NotificationManager.success("Se han bloqueado exitosamente las horas");
    });
  };

  bloquearHorarios = () => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    var motivoBloqueoHora = "";
    if (motivoBloqueo == "otro") {
      motivoBloqueoHora = motivoBloqueoTxt;
    } else {
      motivoBloqueoHora = motivoBloqueo;
    }

    var data = {
      horarios: this.state.arregloHorario,
      motivoBloqueo: motivoBloqueoHora,
      canal: 5,
      uspId: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "bloquear_horarios/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      this.salir();
      NotificationManager.success("Se han bloqueado exitosamente las horas");
    });
  };

  desbloquearHorarios = () => {
    var data = {
      horarios: this.state.arregloHorario,
      hor_estado: 1,
      hor_motivo: null,
      uspId: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "desbloquear_horarios/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      NotificationManager.success(res.data.mensaje);
      this.salir();
    });
  };

  eliminarHorarios = () => {
    var data = {
      horarios: this.state.arregloHorario,
      usp_id: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "eliminar_horarios/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      if (res.data.mensaje == "eliminadoExito") {
        this.salir();
        NotificationManager.success("Se han eliminado existosamente las horas");
      } else if (res.data.mensaje == "horarioAgendadoYeliminado") {
        NotificationManager.info("Se eliminaron algunas horas, las otras estaban reservadas");
        this.salir();
      } else if (res.data.mensaje == "horarioAgendado") {
        NotificationManager.warning("No se pueden eliminar horas reservadas");
      }
    });
  };

  seleccionarTodos = () => {
    const { horarios, arregloHorario, seleccionado } = this.state;

    if (seleccionado == true) {
      this.setState({
        seleccionado: false,
      });

      for (var j = 0; j < horarios.length; j++) {
        var horId = horarios[j];
        var link = document.getElementById("seleccionar" + horId);

        if (link != null) {
          if (arregloHorario.includes(horId)) {
            link.innerHTML = '<label class="textoBotonesCarga">Seleccionar</label>';
            link.style.backgroundColor = "#fff0";
            link.style.borderColor = "#000";
            var index = arregloHorario.indexOf(horId);
            arregloHorario.splice(index, 1);
          }
        }
      }
    } else {
      this.setState({
        seleccionado: true,
      });

      for (var j = 0; j < horarios.length; j++) {
        var horId = horarios[j];
        var link = document.getElementById("seleccionar" + horId);

        if (link != null) {
          if (!arregloHorario.includes(horId)) {
            arregloHorario.push(horId);
            link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff"></i> <label style="padding: 3px 5px;border-radius: 5px;color:#ffffff;cursor: pointer;">Seleccionado</label>';
            link.style.backgroundColor = "#26a530";
            link.style.borderColor = "#26a530";
          }
        }
      }
    }
  };

  traerMotivosBloqueo() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_bloqueo_horario/?tipo=2&cesfam=" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var motivosBloqueo = res.data;

        if (!this.state.herramientas.includes("H24")) {
          var motivo = motivosBloqueo.filter((x) => x.mb_id != 25);
          motivosBloqueo = motivo;
        }

        this.setState({
          motivosBloqueo: motivosBloqueo,
        });
      });
  }

  ocultarCrearHorarioBloqueo = () => {
    this.setState({ crearHorarioBloqueo: false });
    this.horario();
  };

  ocultarCrearHorarioBloqueoExito = () => {
    this.salir();
  };

  ocultar_agendar = () => {
    this.setState({ agendarHora: false });
    this.horario();
  };

  ocultar_cambiar_bloqueo = () => {
    this.setState({ cambiarBloqueo: false });
    this.horario();
  };

  ocultar_cambiar_bloqueo_exito = () => {
    this.setState({ cambiarBloqueo: false });
    this.desSeleccionarTodos();
    this.horario();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  desSeleccionarTodos = () => {
    const { horarios, arregloHorario } = this.state;

    this.setState({
      seleccionado: false,
    });

    for (var j = 0; j < horarios.length; j++) {
      var horId = horarios[j];
      var link = document.getElementById("seleccionar" + horId);

      if (link != null) {
        if (arregloHorario.includes(horId)) {
          link.innerHTML = '<label class="textoBotonesCarga">Seleccionar</label>';
          link.style.backgroundColor = "#fff0";
          link.style.borderColor = "#000";
          var index = arregloHorario.indexOf(horId);
          arregloHorario.splice(index, 1);
        }
      }
    }
  };

  cambiarEspecialidad = (horId) => {
    this.setState(
      {
        cambiarEspecialidad: true,
        horarioAgendar: horId,
      },
      () => {
        this.horario();
      }
    );
  };

  ocultar_cambiar_especialidad = () => {
    this.setState({ cambiarEspecialidad: false });
  };

  ocultar_cambiar_especialidad_exito = () => {
    this.setState({ cambiarEspecialidad: false });
    this.horario();
  };

  cambiarRut = (horId, especialidad, agenda) => {
    this.setState({
      cambiarRut: true,
      horarioAgendar: horId,
      nombreEspecialidad: especialidad,
      agendaReservada: agenda,
    });
  };

  ocultar_cambiar_rut = () => {
    this.setState({ cambiarRut: false });
    this.horario();
  };

  anularHora = (agenda, usuId) => {
    this.setState({
      anularHora: true,
      agendaReservada: agenda,
      usuarioReserva: usuId,
    });
  };

  ocultar_anular_hora = () => {
    this.setState({ anularHora: false });
    this.horario();
  };

  mostrarModalAnunciarLlegada = (ageId, especialidad, usuId) => {
    confirmAlert({
      title: "",
      message: "Desea confirmar la llegada de este paciente?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.traerExamenes(ageId, especialidad, usuId),
          className: "btnAceptar",
        },
      ],
    });
  };

  traerExamenes = async (agenda, especialidad, usuId) => {
    axios
      .get(global.config.modulos.especialidades + "examenes/", {
        headers: authHeader(),
        params: {
          especialidad: especialidad,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        if (data.length > 0) {
          this.confirmarExamen(agenda, usuId);
        } else {
          this.anunciarLlegada(agenda);
        }
      })
      .catch((err) => {});
  };

  confirmarExamen = (agenda, usuId) => {
    confirmAlert({
      title: "",
      message: "¿El paciente tiene la orden para el examen?",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
          onClick: () => {
            NotificationManager.error("La orden de examen es obligatoria para realizar la reserva y posteriormente el examen, una vez que el paciente presente la orden del examen podrá reservar la hora de atención.");
          },
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () => {
            this.mostrarCompletarFolio(agenda, usuId);
          },
        },
      ],
    });
  };

  mostrarCompletarFolio = (agenda, usuId) => {
    this.setState({
      completarFolio: true,
      agendaReservada: agenda,
      usuarioReserva: usuId,
    });
  };

  ocultarCompletarFolio = () => {
    this.setState({
      completarFolio: false,
    });
    this.horario();
  };

  anunciarLlegada = (ageId) => {
    var data = {
      agenda_id: ageId,
      usuario_panel: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "confirmar_llegada/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Llegada confirmada con éxito");
        this.horario();
      }
    });
  };

  sobreCupo = (e) => {
    this.setState({ sobreCupo: true });
  };

  ocultarSobreCupo = (e) => {
    this.setState({ sobreCupo: false });
  };

  ocultarSobreCupoExito = (e) => {
    this.setState({ sobreCupo: false });
    this.horario();
  };

  observacion = (obs) => {
    this.setState({ observacion: true, datosObs: obs });
  };

  ocultarObservacion = (e) => {
    this.setState({ observacion: false });
  };

  ingresarObservacion = (horario, especialidad, usuId) => {
    axios
      .get(global.config.modulos.agendamientos + "motivos_reserva_horario/", {
        headers: authHeader(),
        params: {
          especialidad: especialidad,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            motivos: res.data.motivos,
            horarioReserva: horario,
            cesfamReserva: this.state.cesfam,
            usuarioReserva: usuId,
            tipoReserva: res.data.tipo,
            motivoReserva: true,
          });
        }
      });
  };

  ocultarMotivoReserva = () => {
    this.setState({
      motivoReserva: false,
    });
  };

  ocultarMotivoReservaCreada = () => {
    this.setState({
      motivoReserva: false,
    });
    this.horario();
  };

  render() {
    const { motivosBloqueo, motivoBloqueo, motivoBloqueoTxt, seleccionado } = this.state;

    const cambioMotivoBloqueo = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: true,
        });
      } else {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: false,
        });
      }
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="horarioDiarioProfesional">
        <DialogContent>
          {this.state.agendarHora ? <AgendarHora ocultar={this.ocultar_agendar} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreProfesional}></AgendarHora> : null}

          {this.state.anularHora ? <AnularHora ocultar={this.ocultar_anular_hora} ageid={this.state.agendaReservada} usuId={this.state.usuarioReserva}></AnularHora> : null}

          {this.state.cambiarRut ? <CambiarRut ocultar={this.ocultar_cambiar_rut} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreProfesional} ageId={this.state.agendaReservada}></CambiarRut> : null}

          {this.state.cambiarBloqueo ? <CambiarBloqueo ocultar={this.ocultar_cambiar_bloqueo} ocultarExito={this.ocultar_cambiar_bloqueo_exito} horario={this.state.arregloHorario}></CambiarBloqueo> : null}

          {this.state.cambiarEspecialidad ? <CambiarEspecialidad ocultar={this.ocultar_cambiar_especialidad} ocultarExito={this.ocultar_cambiar_especialidad_exito} horario={this.state.horarioAgendar} profesional={this.state.proId}></CambiarEspecialidad> : null}

          {this.state.completarFolio ? <CompletarFolio ocultar={this.ocultarCompletarFolio} ageId={this.state.agendaReservada} usuId={this.state.usuarioReserva}></CompletarFolio> : null}

          {this.state.sobreCupo ? <SobreCupo ocultar={this.ocultarSobreCupo} ocultarExito={this.ocultarSobreCupoExito} profesional={this.state.proId} fecha={this.state.fechaHorario}></SobreCupo> : null}

          {this.state.observacion ? <Observacion ocultar={this.ocultarObservacion} observacion={this.state.datosObs}></Observacion> : null}

          {this.state.motivoReserva ? <MotivoReserva motivos={this.state.motivos} horarioReserva={this.state.horarioReserva} usuarioReserva={this.state.usuarioReserva} tipoReserva={this.state.tipoReserva} ocultarAgendar={this.ocultarMotivoReservaCreada} ocultar={this.ocultarMotivoReserva} omitir={false} /> : null}

          <GridContainer spacing={3} className="horasProfesional">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Bloquear horas</h2>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 30 }}>
              <GridContainer className="agendar-container">
                <GridItem xs={3}>
                  <p style={{ fontWeight: "bold" }}>Nombre profesional</p>
                  <p style={{ marginTop: 5 }}>{this.state.nombreProfesional}</p>
                </GridItem>
                <GridItem xs={3}>
                  <p style={{ fontWeight: "bold" }}>Fecha</p>
                  <p style={{ marginTop: 5 }}>{moment(this.state.fechaHorario, "YYYY-MM-DD").format("DD-MM-YYYY")}</p>
                </GridItem>
                <GridItem xs={3}>
                  <p style={{ fontWeight: "bold" }}>Especialidad</p>
                  <p style={{ marginTop: 5 }}>{this.state.nombreEspecialidad}</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div
                id="tablahorarioProfesional"
                style={{
                  marginTop: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{
                    marginTop: "20px",
                    overflow: "auto",
                    background: "transparent",
                  }}
                >
                  {this.state.tabla}
                </TableContainer>
              </div>
            </GridItem>
            {moment(this.state.fechaHorario).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD") ? (
              <React.Fragment>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ float: "right" }}>
                    <Checkbox color="primary" onChange={this.seleccionarTodos} defaultChecked={seleccionado} id="marcar" />
                    <label for="marcar">Seleccionar todas</label>
                  </div>
                </GridItem>

                {this.state.herramientas.includes("H77") && (
                  <>
                    <GridItem xs={3} style={{ float: "right", marginTop: 20 }}>
                      <InputLabel style={{ color: "black" }}>Motivo de bloqueo</InputLabel>
                      <Select name="motivoBloqueo" className={"selectSimple"} value={motivoBloqueo} onChange={cambioMotivoBloqueo} style={{ minWidth: 250 }} displayEmpty>
                        <MenuItem value={"0"} key={0}>
                          {"Selecciona"}
                        </MenuItem>
                        {motivosBloqueo &&
                          motivosBloqueo.map((mb, index) => (
                            <MenuItem value={mb.mb_descripcion} key={index}>
                              {mb.mb_nombre}
                            </MenuItem>
                          ))}
                      </Select>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} className="selectMotivoBloqueo">
                      {this.state.mostrarMotivoTxt ? <TextareaAutosize name="motivoBloqueoTxt" aria-label="minimum height" className={"selectSimple "} style={{ float: "left", minWidth: 250, marginTop: 20 }} rowsMin={3} placeholder="Escribe el motivo..." value={motivoBloqueoTxt} onChange={this.cambiarValores} /> : null}
                    </GridItem>
                  </>
                )}

                <GridItem xs={12} sm={12} md={12} className="botonesHorarioDiario">
                  <Button variant="contained" className="btnCargaHorario fondoNaranjo" onClick={this.salir}>
                    Cerrar
                  </Button>

                  {this.state.herramientas.includes("H79") && (
                    <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.eliminarHorarioConfirmar}>
                      Eliminar
                    </Button>
                  )}
                  {this.state.existenHorasBloqueadas && this.state.herramientas.includes("H78") && (
                    <Button variant="contained" className="btnCargaHorario fondoVerde" onClick={this.desbloquearHorarioConfirmar}>
                      Desbloquear
                    </Button>
                  )}
                  {this.state.herramientas.includes("H77") && (
                    <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.bloquearHorarioConfirmar}>
                      Bloquear
                    </Button>
                  )}
                  {this.state.existenHorasBloqueadas && this.state.herramientas.includes("H80") && (
                    <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.cambiarBloqueo}>
                      Cambiar bloqueo
                    </Button>
                  )}

                  {this.state.existenHorasReservadas && this.state.herramientas.includes("H94") && (
                    <Button variant="contained" className="btnCargaHorario" style={{ backgroundColor: "#1cabdd" }} onClick={this.sobreCupo}>
                      Sobrecupo
                    </Button>
                  )}

                  {this.state.crearHorarioBloqueo ? <CrearHorarioBloqueo profesional={this.state.proId} especialidad={this.state.especialidad} horasConAgenda={this.state.horasConAgenda} horarios={this.state.arregloHorario} motivoBloqueoHora={this.state.motivoBloqueoHora} ocultar={this.ocultarCrearHorarioBloqueo} ocultarExito={this.ocultarCrearHorarioBloqueoExito}></CrearHorarioBloqueo> : null}
                </GridItem>
              </React.Fragment>
            ) : null}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
