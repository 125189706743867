import React from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { FaUserAlt, FaMedkit } from "react-icons/fa";
import Menu_bloqueos from "../../assets/img/menu_bloqueos.svg";
import Menu_demanda from "../../assets/img/menu_demanda.svg";
import Menu_covid from "../../assets/img/menu_covid.svg";
import Menu_no_covid from "../../assets/img/menu_no_covid.svg";
import Menu_plan_contigo from "../../assets/img/menu_plan_contigo.svg";
import Menu_plan_contigo_salud from "../../assets/img/menu_plan_contigo_salud.svg";
import Menu_apoyo_social from "../../assets/img/menu_apoyo_social.svg";
import Menu_carga_masiva from "../../assets/img/menu_carga_masiva.svg";
import Menu_horas_anuladas from "../../assets/img/menu_horas_anuladas.svg";
import Menu_recordatorios_externos from "../../assets/img/menu_recordatorios_externos.svg";

import Menu_vacunatorio from "../../assets/img/iconos_menu/vacunas.svg";
import Menu_beneficios from "../../assets/img/iconos_menu/beneficios finalizados.svg";
import Menu_profesionales_teleconsulta from "../../assets/img/iconos_menu/profesionales teleconsulta.svg";
import Menu_grafico_apps from "../../assets/img/iconos_menu/graficos aplicaciones.svg";
import Menu_horas from "../../assets/img/iconos_menu/horas.svg";
import Menu_nsp from "../../assets/img/iconos_menu/menu_nsp.svg";
import Menu_oferta_demanda from "../../assets/img/iconos_menu/oferta demanda.svg";
import Menu_policonsultantes from "../../assets/img/iconos_menu/policonsultantes.svg";
import Menu_percapitados from "../../assets/img/iconos_menu/no percapitados.svg";
import Menu_difusion from "../../assets/img/iconos_menu/menu_difusion.svg";
import Menu_lista_de_espera from "../../assets/img/iconos_menu/menu_lista_de_espera.svg";
import Menu_graficos_teleconsulta from "../../assets/img/iconos_menu/graficos teleconsulta.svg";
import Menu_horarios from "../../assets/img/horarios_profesionales.svg";
import Menu_funcionarios from "../../assets/img/imagenesMenu/gestion_funcionarios.svg";
import Menu_gestion_domiciliaria from "../../assets/img/iconos_menu/gestion_entrega_domiciliaria.svg";

//import {} from "react-icons/ai";
//import {} from "react-icons/io";

export const SidebarData = [
  {
    title: "Pacientes",
    path: "/buscarRut",
    icon: <FaUserAlt style={{ fontSize: 28 }} />,
    cName: "nav-text",
    sigla: "",
    submenu: [],
    grupo: "gestion_paciente",
    estado: "1",
  },
  {
    title: "Carga Masiva",
    path: "/cargarCiclos",
    icon: <img src={Menu_carga_masiva} />,
    cName: "nav-text",
    sigla: "V9",
    submenu: [],
    grupo: "gestion_paciente",
    estado: "1",
  },
  {
    title: "Beneficios Finalizados",
    path: "/Beneficios",
    icon: <img src={Menu_beneficios} />,
    cName: "nav-text",
    sigla: "V14",
    submenu: [],
    grupo: "gestion_paciente",
    estado: "1",
  },
  {
    title: "No Percapitados",
    path: "/noPercapitados",
    icon: <img src={Menu_percapitados} />,
    cName: "nav-text",
    sigla: "V21",
    submenu: [],
    grupo: "gestion_paciente",
    estado: "1",
  },
  {
    title: "Horas anuladas",
    path: "/listaAnulaciones",
    icon: <img src={Menu_horas_anuladas} />,
    cName: "nav-text",
    sigla: "V11",
    submenu: [],
    grupo: "gestion_paciente",
    estado: "1",
  },
  {
    title: "Profesional",
    path: "/buscarProfesional",
    icon: <FaMedkit style={{ fontSize: 28 }} />,
    cName: "nav-text",
    sigla: "V1",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Gestión de bloqueos",
    path: "/listaBloqueos",
    icon: <img src={Menu_bloqueos} />,
    cName: "nav-text",
    sigla: "V2",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Cambios de Hora",
    path: "/cambioHoras",
    icon: <img src={Menu_horas} />,
    cName: "nav-text",
    sigla: "V27",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Horario de Profesionales",
    path: "/horarios",
    icon: <img src={Menu_horarios} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V28",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Profesionales Telemedicina",
    path: "/listaTeleconsulta",
    icon: <img src={Menu_profesionales_teleconsulta} />,
    cName: "nav-text",
    sigla: "V15",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Pacientes Telemedicina",
    path: "/pacientes",
    icon: <img src={Menu_apoyo_social} />,
    cName: "nav-text",
    sigla: "V26",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Gestión nivel 2",
    path: "/GestionPrioritarios",
    icon: <img src={Menu_recordatorios_externos} />,
    cName: "nav-text",
    sigla: "V24",
    submenu: [],
    grupo: "gestion_demanda",
    estado: "1",
  },
  {
    title: "Gestión nivel 3",
    path: "/demanda",
    icon: <img src={Menu_demanda} />,
    cName: "nav-text",
    sigla: "V3",
    submenu: [],
    grupo: "gestion_demanda",
    estado: "1",
  },
  {
    title: "Dental Junaeb",
    path: "/rechazoJunaeb",
    icon: <img src={Menu_demanda} />,
    cName: "nav-text",
    sigla: "V53",
    submenu: [],
    grupo: "gestion_demanda",
    estado: "1",
  },
  {
    title: "Traspaso a FCE",
    path: "/traspaso",
    icon: <img src={Menu_horas} />,
    cName: "nav-text",
    sigla: "V17",
    submenu: [],
    grupo: "fce_externa",
    estado: "1",
  },
  {
    title: "No Covid",
    path: "/gestionNoCovid",
    icon: <img src={Menu_no_covid} />,
    cName: "nav-text",
    sigla: "V4",
    submenu: [],
    grupo: "plan_externo",
    estado: "0",
  },
  {
    title: "Covid",
    path: "/gestionCovid",
    icon: <img src={Menu_covid} />,
    cName: "nav-text",
    sigla: "V5",
    submenu: [],
    grupo: "plan_externo",
    estado: "0",
  },
  {
    title: "Plan Contigo",
    path: "/gestionPlanContigo",
    icon: <img src={Menu_plan_contigo} />,
    cName: "nav-text",
    sigla: "V6",
    submenu: [],
    grupo: "plan_externo",
    estado: "1",
  },
  {
    title: "Plan Contigo Salud",
    path: "/gestionPlanContigoSalud",
    icon: <img src={Menu_plan_contigo_salud} />,
    cName: "nav-text",
    sigla: "V7",
    submenu: [],
    grupo: "plan_externo",
    estado: "1",
  },
  {
    title: "Apoyo Social",
    path: "/gestionApoyoSocial",
    icon: <img src={Menu_apoyo_social} />,
    cName: "nav-text",
    sigla: "V8",
    submenu: [],
    grupo: "plan_externo",
    estado: "0",
  },
  {
    title: "Recordatorios externos",
    path: "/menuReservaExterna",
    icon: <img src={Menu_recordatorios_externos} />,
    cName: "nav-text",
    sigla: "V12",
    submenu: [],
    grupo: "plan_externo",
    estado: "1",
  },
  {
    title: "Lista De Espera",
    path: "/FiltroListaEspera",
    icon: <img src={Menu_lista_de_espera} />,
    cName: "nav-text",
    sigla: "V20",
    submenu: [],
    grupo: "plan_externo",
    estado: "1",
  },
  {
    title: "Vacunación",
    path: "/vacunatorio",
    icon: <img src={Menu_vacunatorio} />,
    cName: "nav-text",
    sigla: "V13",
    submenu: [],
    grupo: "plan_externo",
    estado: "0",
  },
  {
    title: "Gráficos App",
    path: "/graficoAplicaciones",
    icon: <img src={Menu_grafico_apps} />,
    cName: "nav-text",
    sigla: "V16",
    submenu: [],
    grupo: "plan_externo",
    estado: "0",
  },
  /*   {
    title: "Nsp",
    path: "/Nsp",
    icon: <img src={Menu_nsp} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V32",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Policonsultantes",
    path: "/Ranking",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V33",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Oferta y Demanda",
    path: "/director",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V36",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  }, */
  {
    title: "Ticket de trabajo",
    path: "/solicitud_trabajo",
    icon: <img src={Menu_lista_de_espera} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "H49",
    submenu: [],
    grupo: "solicitudes",
    estado: "1",
  },
  {
    title: "Confirmación de tickets",
    path: "/confirmacion_solicitud",
    icon: <img src={Menu_lista_de_espera} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "H50",
    submenu: [],
    grupo: "solicitudes",
    estado: "1",
  },
  {
    title: "Administrar tickets",
    path: "/administrar_solicitud",
    icon: <img src={Menu_lista_de_espera} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "H51",
    submenu: [],
    grupo: "solicitudes",
    estado: "1",
  },
  {
    title: "Desarrollo tickets",
    path: "/desarrollo_solicitud",
    icon: <img src={Menu_lista_de_espera} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "H56",
    submenu: [],
    grupo: "solicitudes",
    estado: "1",
  },
  {
    title: "Espera de producción",
    path: "/espera_produccion",
    icon: <img src={Menu_lista_de_espera} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "H75",
    submenu: [],
    grupo: "solicitudes",
    estado: "1",
  },

  {
    title: "Pasar Número",
    path: "/pasa_numero",
    icon: <img src={Menu_horarios} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V30",
    submenu: [],
    grupo: "gestor_totem",
    estado: "1",
  },
  {
    title: "Administrar totem",
    path: "/totems",
    icon: <img src={Menu_horarios} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V31",
    submenu: [],
    grupo: "gestor_totem",
    estado: "1",
  },
  {
    title: "Difusión",
    path: "/difusiones",
    icon: <img src={Menu_difusion} />,
    cName: "nav-text",
    sigla: "V22",
    submenu: [],
    grupo: "pendiente",
    estado: "1",
  },
  {
    title: "Metas",
    path: "/Metas",
    icon: <img src={Menu_percapitados} />,
    cName: "nav-text",
    sigla: "V23",
    submenu: [],
    grupo: "pendiente",
    estado: "1",
  },
  {
    title: "Cámara hiperbárica",
    path: "/CamaraHiperbarica",
    icon: <img src={Menu_lista_de_espera} />,
    cName: "nav-text",
    sigla: "V40",
    submenu: [],
    grupo: "plan_externo",
    estado: "1",
  },
  {
    title: "Carga de Horarios",
    path: "/TraerCargas",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V47",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Datos Imagenología",
    path: "/dataImagenologia",
    cName: "nav-text",
    sigla: "V51",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Datos Purranque",
    path: "/dataPurranque",
    cName: "nav-text",
    sigla: "V52",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Gestión de agendas",
    path: "/datosGenerales",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V48",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Graficos LLamadas",
    path: "/graficoLlamadas",
    cName: "nav-text",
    sigla: "V54",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Graficos por Sector",
    path: "/graficos_sectores",
    cName: "nav-text",
    sigla: "V45",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Graficos Profesionales",
    path: "/graficos_profesionales",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V46",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Métricas",
    path: "/graficos_generales",
    icon: <img src={Menu_oferta_demanda} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V37",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Nsp",
    path: "/nsp",
    icon: <img src={Menu_nsp} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V38",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Oferta Demanda",
    path: "/vistaOfertaDemanda",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V19",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Policonsultantes",
    path: "/ranking",
    icon: <img src={Menu_policonsultantes} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V39",
    submenu: [],
    grupo: "reporteria",
    estado: "1",
  },
  {
    title: "Accesos",
    path: "/funcionarios",
    icon: <img src={Menu_funcionarios} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "H57",
    submenu: [],
    grupo: "gestion_funcionarios",
    estado: "1",
  },
  {
    title: "Sectores",
    path: "/funcionariosSec",
    icon: <img src={Menu_gestion_domiciliaria} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V56",
    submenu: [],
    grupo: "gestion_funcionarios",
    estado: "1",
  },
  {
    title: "Gestion de entrega",
    path: "/gestion_entrega",
    icon: <img src={Menu_gestion_domiciliaria} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V43",
    submenu: [],
    grupo: "gestion_domiciliaria",
    estado: "1",
  },
  {
    title: "Imagenología",
    path: "/imagenologia",
    icon: <img src={Menu_gestion_domiciliaria} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V49",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Horario de Imagenología",
    path: "/horarios_img",
    icon: <img src={Menu_gestion_domiciliaria} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V50",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
  {
    title: "Horario Especialidad",
    path: "/horarios_esp",
    icon: <img src={Menu_gestion_domiciliaria} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V55",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },

  {
    title: "Pacientes",
    path: "/pacientes_pro",
    icon: <img src={Menu_gestion_domiciliaria} style={{ width: "2vw" }} />,
    cName: "nav-text",
    sigla: "V57",
    submenu: [],
    grupo: "gestion_profesional",
    estado: "1",
  },
];
