import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";
import csvAtencionesExterna_1 from "../../assets/img/imgCargaMasiva/reservaExterna.png";
import csvAtencionesExterna_2 from "../../assets/img/imgCargaMasiva/Externa2.png";
import csvAtencionesExterna_3 from "../../assets/img/imgCargaMasiva/reservaExternaTipo3.png";
import { store } from "./../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import signo from "./../../assets/img/signo.svg";
import InputLabel from "@material-ui/core/InputLabel";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class CargarReservasExternas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      beneficios: [],
      atencionesCM: [],
      atencion: "",
      tipo_atencion: "",
      beneficio: null,
      id_beneficio: null,
      tipo_beneficio: null,
      ultimaCarga: null,
      datos: [],
      tipo_carga: "cargarCondiciones",
      herramientas: store.getState().herramientas,
      id_carga: null,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.titulo();
    this.atencionesCargaMasiva();
  }

  ultimaCarga() {
    axios
      .get(global.config.modulos.cm_atenciones_externas + "ultima_carga/", {
        headers: authHeader(),
        params: { id_usp: this.state.uspId, id_atencion: this.state.atencion, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          let fecha = res.data.datos.hec_fecha_carga.split("T");
          var dia = String(fecha[0]).split("-");
          dia = dia[0] + "-" + dia[1] + "-" + dia[2];
          this.setState({ ultimaCarga: "Última carga: " + dia + " a las " + fecha[1], id_carga: res.data.datos.hec_id });
        } else {
          this.setState({ ultimaCarga: null, id_carga: null });
        }
      });
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva  <span title='Permite ingresar o actualizar a través de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  cambioValor = (event) => {
    const { name, value } = event.target;
    let beneficio = value.split(";");
    let id_beneficio = beneficio[0];
    let tipo_beneficio = beneficio[1];
    this.setState({
      [name]: value,
      id_beneficio: id_beneficio,
      tipo_beneficio: tipo_beneficio,
    });
  };

  handleOnDrop = (data, file) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    this.setState({ datos: registros, archivo: file });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    this.cargarArchivo();
    const { archivo } = this.state;

    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.cm_atenciones_externas + "carga_masiva_reservas_externas/",
        {
          atenciones: this.state.datos,
          id_usp: this.state.uspId,
          id_especialidad: this.state.atencion,
          tipo_atencion: this.state.tipo_atencion,
          cesfam: this.state.cesfam,
          nombreArchivo: archivo.name,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success(res.data.mensaje, "", 5 * 60 * 1000);
        } else {
          NotificationManager.warning(res.data.mensaje, "", 5 * 60 * 1000);
        }
      });
  }

  atencionesCargaMasiva() {
    axios
      .get(global.config.modulos.cm_atenciones_externas + "atenciones_carga_masiva/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ atencionesCM: res.data.datos });
        }
      });
  }

  cambiarVista = (e) => {
    const { name, value } = e.target;
    this.setState({ tipo_carga: value }, () => {
      this.props.history.push(this.state.tipo_carga);
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    let valores = value.split(";");
    this.setState({ [name]: parseInt(valores[0]), tipo_atencion: parseInt(valores[1]) }, () => {
      this.ultimaCarga();
    });
  };

  descargarExcel = (e) => {
    const url = global.config.modulos.cm_atenciones_externas + "excel_errores/?id_carga=" + this.state.id_carga;
    window.open(url, "_blank").focus();
  };

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  cargarArchivo = async () => {
    const { archivo } = this.state;
    const data = new FormData();
    data.append("file", archivo);
    data.append("filename", archivo.name);
    await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_masiva", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return archivo.name;
  };

  render() {
    const { datos, atencionesCM, atencion, tipo_atencion, ultimaCarga } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={moduleCM.divIcon}>
                    <img className={moduleCM.imgCMciclos1} src={carga_ciclos} onClick={this.cargarCiclos} />

                    <img className={moduleCM.imgCMbeneficio} src={carga_beneficios} onClick={this.cargarBeneficios} />

                    <img className={moduleCM.imgCMfallecido} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                    {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                    {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna1} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  {ultimaCarga && (
                    <Grid item xs={12} sm={12}>
                      <Button
                        onClick={this.descargarExcel}
                        style={{
                          backgroundColor: "#bf3c50",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Descargar errores - {ultimaCarga}
                      </Button>
                    </Grid>
                  )}
                  <br></br>
                  {atencionesCM && (
                    <Grid item xs={12} sm={12}>
                      <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                        Seleccione una atención
                      </InputLabel>
                      <Select className={moduleCM.selectCiclos} id="atencion" name="atencion" value={atencion ? atencion + ";" + tipo_atencion : ""} onChange={this.cambiarValores} displayEmpty>
                        <MenuItem value="" disabled>
                          <em></em>
                        </MenuItem>
                        {atencionesCM.map((cm, index) => (
                          <MenuItem value={cm.id_especialidad + ";" + cm.tipo} key={index}>
                            {cm.nombre_especialidad}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  <br></br>
                  {tipo_atencion && (
                    <Grid item xs={12} sm={12}>
                      <span>Cargar CSV</span>
                      <div className={moduleCM.csvCargaMasiva}>
                        <CSVReader
                          onDrop={this.handleOnDrop}
                          onError={this.handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.handleOnRemoveFile}
                          config={{
                            header: true,
                            dynamicTyping: true,
                            skipEmptyLines: true,
                            transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                          }}
                        >
                          <div
                            className={moduleCM.divSpanArchivo}
                            style={{
                              width: "21vw",
                            }}
                          >
                            <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                            <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                          </div>
                        </CSVReader>
                        <LinearProgress id="cargando" style={{ display: "none" }} />
                      </div>
                    </Grid>
                  )}
                  {datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  {tipo_atencion == 1 && <img className={moduleCM.imgEjemploCiclo} src={csvAtencionesExterna_1} alt="csv" />}
                  {tipo_atencion == 2 && <img className={moduleCM.imgEjemploCiclo} src={csvAtencionesExterna_2} alt="csv" />}
                  {tipo_atencion == 3 && <img className={moduleCM.imgEjemploCiclo} src={csvAtencionesExterna_3} alt="csv" />}
                </Grid>
              </Grid>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
